import { Component, OnInit } from '@angular/core';
import { DeviceService } from '../../../shared/service/device/device.service';
import { ModalService } from '../../../shared/service/modal/modal.service';
import { CommonService } from '../../../shared/service/common/common.service';
import { AppDownloadService } from '../../../shared/service/app-download/app-download.service';
import { Subject, takeUntil } from 'rxjs';
import { LocalizedMessages } from '../../../shared/models/i18n.model';
import { langxObj } from './app-download_langx';
import { PlatformService } from '../../../shared/service/platform/platform.service';

@Component({
  selector: 'app-app-download',
  templateUrl: './app-download.component.html',
  styleUrl: './app-download.component.scss'
})
export class AppDownloadComponent implements OnInit {

  private destroy$ = new Subject<void>();

  langx: LocalizedMessages = {};

  constructor(
    private appDownloadService: AppDownloadService,
    private deviceService: DeviceService,
    private modalService: ModalService,
    private commonService: CommonService,
    private platformService: PlatformService
  ) {
    this.commonService.currentLanguage$
      .pipe(takeUntil(this.destroy$))
      .subscribe(currentLanguage => {
        this.langx = langxObj[currentLanguage];
      });
  }

  ngOnInit(): void {
    if (this.platformService.isBrowser()) {

      this.appDownloadService.getDownloadLinks().subscribe(response => {
        if (response.isSuccess) {
          
          if (this.deviceService.isAndroid()) {
            window.location.href = response.data?.androidDownloadLink || '';
          } else if (this.deviceService.isIos()) {
            window.location.href = response.data?.iosDownloadLink || '';
          } else {
            this.modalService.showError(this.langx['UNSUPPORTED_DEVICE_ERROR_MESSAGE']);
          }
        } else {

          if (response.errors) {
            this.modalService.showError(response.errors[0].message);
          }
        }
      });
      
    }
  }

}
