<div class="maintenance-wrapper">
    <div class="logo text-center">
        <img src="{{ mediaServer }}/zeta/maintenance/logo_wellbet-redd.png" alt="">
    </div>

    <div class="title text header text-black text-center">
        {{ langx['TITLE'] }}
    </div>

    <div class="texts text-small">

        <div class="greeting text-black">
            {{ langx['GREETING'] }}
        </div>

        <div class="reason">
            {{ langx['REASON_LABEL'] }}
        </div>

        <div class="message text-red">
            {{ maintenanceMessage }}
        </div>

        <div class="tips text-black">
            {{ langx['TIPS_LINE'] }}
        </div>

        @if(isWithCS){
        <div class="cs-chat">
            <div class="line">
                {{ langx['CS_LINE'] }}
            </div>
            <div class="button" (click)="onContactCsLiveClick()">
                <span class="icon">
                    <img src="{{ mediaServer }}/zeta/maintenance/icon_live-cs.png" alt="">
                </span>
                <span class="label text-white">{{ langx['CS_ICON_LABEL'] }}</span>
            </div>
            <div class="bottom-line text-black">
                {{ langx['CS_BOTTOM_LINE'] }}
            </div>
        </div>
        }
    </div>
</div>