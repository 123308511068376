import { Location } from '@angular/common';
import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { of, switchMap, tap } from 'rxjs';
import { CommonService } from '../common/common.service';
import { PlatformService } from '../platform/platform.service';
import { UserService } from '../user/user.service';
import { AuthService } from './auth.service';

export const authGuard: CanActivateFn = () => {

  const commonService = inject(CommonService);
  const authService = inject(AuthService);
  const router = inject(Router);
  const location = inject(Location);
  const userService = inject(UserService);
  const platformService = inject(PlatformService);

  if (platformService.isServer()) {
    return true;
  }

  return commonService.domains$.pipe( // make sure domains object is ready first
    switchMap(() => authService.checkSession()), // change source to http call observable
    switchMap(response => of(Boolean(response.isSuccess))), // change source to boolean
    tap(canActivate => {
      if (!canActivate) {
        router.navigate([(location.path().includes('/en-gb') ? 'en-gb' : 'zh-cn') + '/user/login']);

      } else {
        userService.resolveCurrentUserFromCookies();
      }
    })
  );
};
