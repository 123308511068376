import { Component, OnDestroy, OnInit } from '@angular/core';
import { interval, Subject, takeUntil } from 'rxjs';
import { LocalizedMessages } from '../../../shared/models/i18n.model';
import { CommonService } from '../../../shared/service/common/common.service';
import { HttpService } from '../../../shared/service/http/http.service';
import { PlatformService } from '../../../shared/service/platform/platform.service';
import { langxObj } from './maintenance_langx';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrl: './maintenance.component.scss'
})
export class MaintenanceComponent implements OnInit, OnDestroy {

  maintenanceMessage: string = '';
  isWithCS: boolean = false;
  mediaServer: string = 'https://media.jxf88.co'; // we can't get this on domains if it's maintenance
  langx: LocalizedMessages = {};
  currentLanguage: string = '';
  destroy$ = new Subject<void>();

  constructor(
    private commonService: CommonService,
    private platformService: PlatformService,
    private httpService: HttpService,
  ) {
  }

  ngOnInit() {
    if( this.platformService.isBrowser() ) {
      this.getAndProcessMaintenanceStatus();

      interval(60000).pipe(
        takeUntil(this.destroy$),
      ).subscribe(() => {
        this.getAndProcessMaintenanceStatus();
      });
    }

    this.commonService.currentLanguage$
      .pipe(takeUntil(this.destroy$))
      .subscribe(currentLanguage => {
        this.currentLanguage = currentLanguage;
        this.langx = langxObj[this.currentLanguage];
      });
  }

  getAndProcessMaintenanceStatus() {
    this.commonService.getMaintenanceStatus()
      .subscribe(response => {
        this.handleMaintenanceStatusResponse(response);
      });
  }

  handleMaintenanceStatusResponse(response: any) {
    this.maintenanceMessage = response.data?.message ?? '';
    this.isWithCS = response.data?.isWithCS ?? false;

    if (!response.data.isMaintenance) {
      this.commonService.disableMaintenance();
    }
  }

  onContactCsLiveClick() {
    this.httpService.forEmptyBaseUrl().get('/api/system/cs/chat/url')
      .subscribe( response => {
        window.open(response.data.ktoCsUrl, '_blank');
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
